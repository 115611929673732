<template>
  <div class="form-section" :class="$store.state['App-UiColorSchema-api'].currentSchemaClasses"  v-if="amReady">
    <div class="title text-info" @click="visibility = !visibility">3 - Control
      <i :class="{'el-icon-arrow-down': visibility, 'el-icon-arrow-right' : (!visibility) == true}"></i>
    </div>

    <el-dialog :visible.sync="dialogVisible" title="" top="3vh" width="1200px" custom-class="">
      <accounting-notes v-if="dialogVisible" title="Internal Notes" :client_id="control.dataModel.id"></accounting-notes>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibleAsset" title="" top="3vh" width="1300px" custom-class="">
      <div style="">
        <accounting-asset v-if="dialogVisibleAsset" title="Internal Notes" :client_id="control.dataModel.id"></accounting-asset>
      </div>
    </el-dialog>

    <div v-show="visibility">
      <el-row>
        <el-col :class="$langConfig.cssClass" style="width: 150px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="14"
              :label_span="10"
              :options="dropdownAccountStatus"
              @amUpdated="statusChanged"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Status"
              name="account_status_id"
              placeholder="Status"
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 210px; margin-left: 10px" v-if="accountStatus == 1">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="13"
              :label_span="11"
              :translateLabel='false'
              field_mark="required"
              format="MM/yyyy"
              label="Client Since"
              name="account_client_since"
              placeholder="mm/yyyy"
              rules="required"
              type="month"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 125px; margin-left: 10px;" v-if="accountStatus == 1">
          <dg-single-checkbox-field
              :labelThenField="true"
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="3"
              :label_span="21"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              field_mark=""
              label="Related Company"
              name="account_related_company"
              rules=""></dg-single-checkbox-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 180px; margin-left: 10px;" v-if="accountStatus == 1">
          <dg-single-checkbox-field
              v-if="accountStatus == 1"
              :labelThenField="true"
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="4"
              :label_span="20"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              field_mark=""
              label="Partner Outside Canada"
              name="account_partner_outside_canada"
              rules=""></dg-single-checkbox-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 85px; margin-left: 0px;" v-if="accountStatus == 1">
          <dg-single-checkbox-field
              v-if="accountStatus == 1"
              :labelThenField="true"
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="4"
              :label_span="20"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              field_mark=""
              label="Print Label"
              name="account_print_label"
              rules=""></dg-single-checkbox-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 120px;margin-left: 10px;" v-if="accountStatus == 0">
          <dg-single-checkbox-field
              v-if="accountStatus == 0"
              :labelThenField="true"
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="4"
              :label_span="18"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              @amUpdated="(p, val) =>  {releasedPageCheckbox = val}"
              field_mark=""
              label="Released Page"
              name="account_released_page"
              rules=""></dg-single-checkbox-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 225px;" v-if="releasedPageCheckbox && accountStatus == 0" >
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="15"
              :label_span="9"
              :translateLabel='false'
              field_mark=""
              format="DD/MM/yyyy"
              label="Release Date"
              name="account_released_page_date"
              placeholder="dd/mm/yyyy"
              rules="required"
              type="date"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 90px;margin-left: 10px;" v-if="accountStatus == 0">
          <dg-single-checkbox-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="5"
              :label_span="17"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              field_mark=""
              @amUpdated="(p, val) =>  {shreddedCheckbox = val}"
              label="Shredded"
              :labelThenField="true"
              name="account_shredded"
              rules="required"></dg-single-checkbox-field>
          <!--        <field-info width="80%">tells if the Client Contract has been shredded.</field-info>-->
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 180px;" v-if="shreddedCheckbox && accountStatus == 0">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="19"
              :label_span="5"
              :translateLabel='false'
              field_mark=""
              format="DD/MM/yyyy"
              label="Date"
              name="account_shredded_date"
              placeholder="dd/mm/yyyy"
              rules="required"
              type="date"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
          <!--        <field-info width="100%">month and year of shredding.</field-info>-->
        </el-col>
      </el-row>

      <el-row class="mt-1" v-if="accountStatus == 1">
        <el-col :class="$langConfig.cssClass" style="width: 110px;">
          <dg-single-checkbox-field
              :labelThenField="true"
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="4"
              :label_span="20"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              @amUpdated=" (p, val) => {directDepositCheckbox = val}"
              field_mark=""
              label="Direct Deposit"
              name="account_direct_deposit"
              rules=""></dg-single-checkbox-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 250px;margin-left: 10px;" v-if="directDepositCheckbox">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="11"
              :label_span="13"
              :translateLabel='false'
              field_mark=""
              format="MM/yyyy"
              label="Direct Deposit Since"
              name="account_direct_deposit_since"
              placeholder="mm/yyyy"
              rules="required"
              type="month"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 160px;margin-left: 10px;" v-if="directDepositCheckbox">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="14"
              :label_span="10"
              :translateLabel='false'
              :valueFormatter="(val) => { return $services['NumUtil'].rmNonDigits(val)}"
              @amUpdated=" (p, val) => { p.field.field_value = $services['NumUtil'].format(val)}"
              field_mark="required"
              maxLength="6"
              label="Fees ($)"
              name="account_direct_deposit_fees"
              placeholder="e.g 12,000"
              rules="required"></dg-text-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 250px;margin-left: 10px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="14"
              :label_span="10"
              :options="taxesPaidByOptions"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Taxes Paid By"
              name="account_taxes_paid_by"
              placeholder="Status"
              rules="required"></dg-select-field>
        </el-col>
      </el-row>

      <el-row v-if="accountStatus == 1" class="">
        <el-col :class="$langConfig.cssClass" style="width: 60px;">
          <dg-single-checkbox-field
              :labelThenField="true"
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="3"
              :label_span="21"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              @amUpdated=" (p, val) => {hasStorage = val}"
              field_mark=""
              label="Storage"
              name="account_storage"
              rules=""></dg-single-checkbox-field>
        </el-col>


        <el-col :class="$langConfig.cssClass" style="width: 160px; margin-left: 15px" v-if="hasStorage">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="17"
              :label_span="7"
              :translateLabel='false'
              field_mark="required"
              format="MM/yyyy"
              label="Since"
              name="account_storage_since"
              placeholder="mm/yyyy"
              rules="required"
              type="month"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 120px;margin-left: 10px;" v-if="hasStorage">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="15"
              :label_span="9"
              :options="[{label: 'Unit', value: 1},{ label: 'Shelf', value: 2}]"
              @amUpdated="(p, val) => {storageTypeId = val}"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Type"
              name="account_storage_type_id"
              placeholder=""
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 135px;margin-left: 10px;" v-if="storageTypeId">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="12"
              :label_span="12"
              :options="storageSlots"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Number"
              name="account_storage_number"
              placeholder="#"
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 140px;margin-left: 10px;" v-if="storageTypeId">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="13"
              :label_span="11"
              :translateLabel='false'
              maxLength="6"
              :valueFormatter="(val) => { return $services['NumUtil'].rmNonDigits(val)}"
              @amUpdated=" (p, val) => { p.field.field_value = $services['NumUtil'].format(val)}"
              field_mark="required"
              label="Fees ($)"
              name="account_storage_fees"
              placeholder="e.g 12,000"
              rules="required"></dg-text-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: fit-content;margin-left: 20px;">
          <el-button @click="openInternalNotes" type="info" size="small" class="p-1"><i class="el-icon-edit-outline"></i> Internal Notes</el-button>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: fit-content;margin-left: 20px;">
          <el-button @click="openAssets" type="info" size="small" class="p-1"><i class="el-icon-edit-outline"></i> Assets </el-button>
        </el-col>
      </el-row>

      <!--<el-row>
        <el-col :class="$langConfig.cssClass" style="width: 90px;">
          <dg-single-checkbox-field
              :labelThenField="true"
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="5"
              :label_span="19"
              :translateLabel='false'
              field_mark=""
              label="Print label"
              name="account_print_label"
              rules=""></dg-single-checkbox-field>
          &lt;!&ndash;        <field-info width="80%">tells if this business has a related file.</field-info>&ndash;&gt;
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 200px;margin-left: 10px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="11"
              :label_span="13"
              :options="dropdownAccountingOptions"
              :translateLabel='false'
              :translateLabels="false"
              @amUpdated="(p, val) =>  { hstReportingSchedulePeriod = val}"
              field_mark="required"
              label="Printing Period"
              name="account_print_label_period_id"
              placeholder=""
              rules="optional"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 120px;margin-left: 10px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="12"
              :label_span="12"
              :options="dropdownMonths"
              :translateLabel='false'
              :translateLabels="false"
              @amUpdated="(p, val) =>  { hstReportingSchedulePeriod = val}"
              field_mark="required"
              label="Period"
              name="account_print_label_period_value"
              placeholder=""
              rules="optional"></dg-select-field>
        </el-col>
      </el-row>-->

      <el-row class="mt-1" v-if="accountStatus == 0">
        <!--<el-col :class="$langConfig.cssClass" style="width: 110px;">
          <dg-single-checkbox-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :defaultValue="has_direct_deposit"
              :field_span="3"
              :labelThenField="true"
              :label_span="21"
              :translateLabel='false'
              @amUpdated="(p, val) =>  {$emit('amUpdated', {'field' : 'selectedDirectDeposit', val: val})}"
              field_mark=""
              label="Direct Deposit"
              name="has_direct_deposit"
              rules="required"></dg-single-checkbox-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 210px;margin-left: 10px;">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="13"
              :label_span="11"
              :translateLabel='false'
              field_mark="required"
              format="MM/yyyy"
              label="Started Date"
              name="direct_deposit_date"
              placeholder="month"
              rules="required"
              type="month"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 230px;margin-left: 10px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="14"
              :label_span="10"
              :translateLabel='false'
              :valueFormatter="(val) => { return $services['NumUtil'].rmNonDigits(val)}"
              @amUpdated=" (p, val) => { p.field.field_value = $services['NumUtil'].format(val)}"
              field_mark="required"
              label="Amount ($)"
              name="direct_deposit_amount"
              placeholder="e.g 12,000"
              rules="required"></dg-text-field>
        </el-col>-->

        <el-col :class="$langConfig.cssClass" style="width: 235px;">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="14"
              :label_span="10"
              :translateLabel='false'
              field_mark="required"
              format="dd/MM/yyyy"
              label="Close Date"
              name="account_date_of_close"
              placeholder="dd/mm/yyyy"
              rules="required"
              type="date"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 220px;margin-left: 10px">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="12"
              :label_span="12"
              :options="dropdownCloseProcessOptions"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              @amUpdated=" (p, val) => {closeProcessStatus = val}"
              label="Close Process"
              name="account_close_process_id"
              placeholder="Select a box #"
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 250px;margin-left: 10px" v-if="closeProcessStatus == 2">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="12"
              :label_span="12"
              :options="dropdownBoxesOptions"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="DAG Storage Box"
              name="account_dag_storage_box"
              placeholder="Box"
              rules="required"></dg-select-field>
        </el-col>
      </el-row>


      <el-row class="mt-1" v-if="accountStatus != null">
        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 24 : 24">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="24"
              :label_span="24"
              :translateLabel='false'
              field_mark=""
              label=""
              inputPrepend="Notes"
              name="account_note"
              placeholder=""
              rules=""></dg-text-field>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import jsonAccountReportingPeriods from "@/json/dropdown/AccountingReportingPeriods.json";
import json12month from "@/json/dropdown/12Month.json";
import jsonAccountStatus from "@/json/dropdown/AccountStatus.json";
import taxesPaidBy from "@/json/dropdown/TaxesPaidBy.json";


export default {
  components: {
    'accounting-notes': () => import('@/modules/Accounting/Note/form/View'),
    'accounting-asset': () => import('@/modules/Accounting/Asset/list/page/View')
  },
  props: {
    control: {
      required: true
    },
    editMode: {
      required: true
    }
  },
  beforeMount() {
    this.accountStatus = this.control.dataModel.accountStatus
    let maxBoxes = 50;

    let boxes = []
    for (let i = 1; i <= maxBoxes; i = i + 1) {
      boxes.push({
        "label": 'Box ' + i,
        "value": i
      },)
    }
    this.dropdownBoxesOptions = boxes


    let slots = [{'label': "None", 'value' : 0}]
    for (let i = 1; i <= maxBoxes; i = i + 1) {
      slots.push({
        "label": '' + i,
        "value": i
      },)
    }

    this.storageSlots = slots
  },
  watch: {
    releasedPageCheckbox: function (val) {
      /*this.amReady = false
      $vue.nextTick( () => {
        this.amReady = true
      })*/
    }
  },
  data () {
    return {
      dialogVisible: false,
      dialogVisibleAsset: false,
      hasStorage: false,
      storageTypeId: 0,
      amReady: true,
      closeProcessStatus: null,
      shreddedCheckbox: null,
      releasedPageCheckbox: null,
      storageShelfCheckbox: null,
      unitCheckbox: null,
      directDepositCheckbox: null,
      accountStatus: null,
      visibility:  true,
      dropdownMonths: json12month,
      taxesPaidByOptions: taxesPaidBy,
      dropdownCloseProcessOptions: [
        {
          label: 'Pending',
          value: 1
        },
        {
          label: 'Completed',
          value: 2
        }
      ],
      dropdownAccountStatus: jsonAccountStatus,
      dropdownAccountingOptions: jsonAccountReportingPeriods,
      dropdownBoxesOptions: [],
      storageSlots: [],
    }
  },
  methods: {
    openInternalNotes () {
      this.dialogVisible = true
    },
    openAssets () {
      this.dialogVisibleAsset = true
    },
    statusChanged(p, val) {
      this.accountStatus = null
      $vue.nextTick( () => {
        this.accountStatus = val
      })
    }
  }
}
</script>
